@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  

  html {
    @apply text-neutral-900 font-poppins h-[100%] no-scrollbar;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply w-full px-4 py-3 font-medium transition duration-200 border-none rounded-lg text-fs-9 text-neutral-100 bg-neutral-10 ring-0 focus:bg-neutral-30 focus:border-none focus:ring-0 placeholder:text-neutral-60 disabled:bg-neutral-40;
  }

  [type='checkbox'] {
    @apply w-5 h-5 transition rounded cursor-pointer focus:ring-0 focus:ring-offset-0 bg-neutral-30 border-neutral-30;
  }

  [type='radio'] {
    @apply w-6 h-6 transition focus:ring-0 focus:ring-offset-0 bg-neutral-30 border-neutral-30;
  }

  body {
    @apply h-[100%] flex flex-col;
  }
}

@layer components {
  /* CARD COMPONENT STYLE */
  .card {
    @apply relative flex flex-col min-w-0 bg-white dark:bg-inverse-primary-light-2;
    box-shadow: 0px 0px 12px rgba(33, 37, 41, 0.1);
  }
  .card-header {
    @apply border-b px-9 border-neutral-20 dark:border-neutral-80 flex-nowrap;
    min-height: 72px;
  }
  .card-body {
    @apply py-6 px-9;
    min-height: 72px;
    flex: 1 1 auto;
  }
  .card-footer {
    @apply border-t px-9 border-neutral-20 dark:border-neutral-80 flex-nowrap;
    min-height: 24px;
  }

  /* FORM COMPONENTS */
  .form-invalid {
    @apply border border-solid border-danger focus:border focus:border-solid focus:border-danger;
  }

  /* BUTTON COMPONENT STYLE  */
  .btn {
    @apply font-semibold transition-all duration-300 rounded-lg outline-none;
  }
  .btn-lg {
    @apply py-[10px] px-[20px] h-[44px] text-fs-8;
  }
  .btn-md {
    @apply py-[9px] px-[16px] h-[38px] text-fs-9;
  }
  .btn-sm {
    @apply py-[8px] px-[16px] h-[32px] text-fs-10;
  }
  .btn-filled-primary {
    @apply text-white border bg-primary border-primary hover:bg-primary-dark-1 disabled:bg-neutral-20 disabled:text-neutral-60 disabled:border-neutral-20;
  }
  .btn-outline-primary {
    @apply border border-primary text-primary hover:bg-primary-light-2 disabled:border-neutral-60 disabled:text-neutral-60;
  }
  .btn-ghost-primary {
    @apply border-none text-primary hover:bg-primary-light-2 disabled:text-neutral-60;
  }
  .btn-action-primary {
    @apply border-none bg-primary-light-2 text-primary;
  }
  .btn-filled-success {
    @apply text-white border bg-success border-success hover:bg-success-dark-1 disabled:bg-neutral-20 disabled:text-neutral-60 disabled:border-neutral-20;
  }
  .btn-outline-success {
    @apply border border-success text-success hover:bg-success-light-2 disabled:border-neutral-60 disabled:text-neutral-60;
  }
  .btn-ghost-success {
    @apply border-none text-success hover:bg-success-light-2 disabled:text-neutral-60;
  }
  .btn-action-success {
    @apply border-none bg-success-light-2 text-success;
  }
  .btn-filled-danger {
    @apply text-white border bg-danger border-danger hover:bg-danger-dark-1 disabled:bg-neutral-20 disabled:text-neutral-60 disabled:border-neutral-20;
  }
  .btn-outline-danger {
    @apply border border-danger text-danger hover:bg-danger-light-2 disabled:border-neutral-60 disabled:text-neutral-60;
  }
  .btn-ghost-danger {
    @apply border-none text-danger hover:bg-danger-light-2 disabled:text-neutral-60;
  }
  .btn-action-danger {
    @apply border-none bg-danger-light-2 text-danger;
  }
  .btn-filled-warning {
    @apply text-white border bg-warning border-warning hover:bg-warning-dark-1 disabled:bg-neutral-20 disabled:text-neutral-60 disabled:border-neutral-20;
  }
  .btn-outline-warning {
    @apply border border-warning text-warning hover:bg-warning-light-2 disabled:border-neutral-60 disabled:text-neutral-60;
  }
  .btn-ghost-warning {
    @apply border-none text-warning hover:bg-warning-light-2 disabled:text-neutral-60;
  }
  .btn-action-warning {
    @apply border-none bg-warning-light-2 text-warning;
  }
  .btn-filled-info {
    @apply text-white border bg-info border-info hover:bg-info-dark-1 disabled:bg-neutral-20 disabled:text-neutral-60 disabled:border-neutral-20;
  }
  .btn-outline-info {
    @apply border border-info text-info hover:bg-info-light-2 disabled:border-neutral-60 disabled:text-neutral-60;
  }
  .btn-ghost-info {
    @apply border-none text-info hover:bg-info-light-2 disabled:text-neutral-60;
  }
  .btn-action-info {
    @apply border-none bg-info-light-2 text-info;
  }

  .button-group-inner-shadow {
    box-shadow: 0px 2px 4px -2px #1018280F inset;
    box-shadow: 0px 4px 8px -2px #1018281A inset;
  }
}

input:checked ~ .dot {
  transform: translateX(100%);
  right: 2px;
}

input:checked ~ .backside {
  background-color: #1d4ed8 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Custom Scrollbar */
/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  @apply bg-neutral-100 rounded-lg
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-neutral-300 rounded-lg
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-neutral-400
}

.custom-scrollbar::-webkit-scrollbar {
  width: 14px;
  height: 10px;
}

.custom-scrollbar{
    scrollbar-color: #D0D5DD #F2F4F7;
    scrollbar-width: thin;
}

/* tinymce hard style  */
.tox-tinymce {
  border-radius: 8px !important;
  border: 1px solid #f5f7f9 !important;
}

.tox .tox-edit-area__iframe {
  background-color: #f5f7f9 !important;
}

.tox-statusbar {
  display: none !important;
}

.tox-toolbar__primary {
  background: none !important;
}

.tox .tox-editor-header {
  z-index: 0 !important;
}

@layer components {
  /* THIS IS FOR CUSTOM CALENDAR */
  .custom-calendar { 
    box-shadow: 0px 8px 8px -4px #10182808;
    box-shadow: 0px 20px 24px -4px #10182814;
    @apply rounded-lg
    
  }

  .custom-calendar .rmdp-wrapper {
    border: 1px solid #000000;
    box-shadow: 0 0 5px #000000;
  }

  .custom-calendar .rmdp-panel-body li {
    background-color: #000000;
    box-shadow: 0 0 2px #000000;
  }

  .custom-calendar .rmdp-week-day {
    @apply font-semibold text-neutral-100;
  }

  .custom-calendar .rmdp-day.rmdp-deactive {
    @apply hover:bg-neutral-20 text-neutral-30;
  }

  .custom-calendar .rmdp-ym .rmdp-day {
    @apply text-neutral-700
  }

  .custom-calendar .rmdp-ym .rmdp-day span {
    @apply rounded-none hover:rounded-none !important
  }

  .custom-calendar .rmdp-range {
    @apply bg-primary
  }
   

  .custom-calendar .rmdp-calendar  {
    @apply py-5 px-6  
  }

  .custom-calendar .rmdp-arrow {
    border-width: 0 2px 2px 0;
    @apply border-neutral-500
  }

  .custom-calendar.rmdp-ep-shadow {
    @apply !hidden
  }

  .custom-calendar .rmdp-arrow-container:hover {
    @apply text-neutral-500
  }


  .custom-calendar .rmdp-arrow-container:hover .rmdp-arrow {
    @apply border-primary-500
  }
  

  .custom-calendar .rmdp-arrow-container:hover {
    @apply bg-transparent shadow-none text-neutral-500
  }

  .custom-calendar .rmdp-panel-body::-webkit-scrollbar-thumb {
    background: #000000 !important;
  }

  .custom-calendar .rmdp-day.rmdp-today:not(.rmdp-range) span {
    @apply !bg-white !text-primary font-semibold;
  }

  .custom-calendar .rmdp-rtl .rmdp-panel {
    border-left: unset;
    border-right: 1px solid #000000;
  }

  .custom-calendar .rmdp-header {
    @apply mb-3 px-5
  }

  .custom-calendar .rmdp-header-values {
    @apply text-neutral-700 text-base font-semibold
  }

  .custom-calendar .rmdp-header-values span {
    @apply text-neutral-700 text-[16px] font-semibold
  }


  /* month picker */
  .custom-calendar .rmdp-month-picker .rmdp-day,
  .custom-calendar .rmdp-year-picker .rmdp-day {
    @apply w-[56px] h-[56px] rounded-lg overflow-hidden
  }

  .custom-calendar .rmdp-month-picker .rmdp-day span,
  .custom-calendar .rmdp-year-picker .rmdp-day span {
    @apply w-[56px] h-[56px] !rounded-md left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
  }

  .custom-calendar .rmdp-month-picker .rmdp-day:not(.rmdp-day-hidden) span:hover:hover,
  .custom-calendar .rmdp-year-picker .rmdp-day:not(.rmdp-day-hidden) span:hover:hover {
    @apply shadow-none  !rounded-md text-primary bg-primary-100
  }

  .custom-calendar .rmdp-month-picker .rmdp-day.rmdp-today span,
  .custom-calendar .rmdp-year-picker .rmdp-day.rmdp-today span {
    @apply bg-white text-primary-500 font-semibold
  }


  /* day picker */
  .custom-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
    @apply text-white bg-primary-500 ;
  }

  .custom-calendar .rmdp-day:not(.rmdp-day-hidden) span:hover {
    @apply hover:bg-neutral-20 hover:text-black;
  }

  .custom-calendar .rmdp-day {
    @apply text-neutral-700
  }

  .custom-calendar .rmdp-day.rmdp-disabled {
    @apply pointer-events-none
  }
  
  .custom-calendar .rmdp-day.rmdp-disabled span {
    @apply text-neutral-300 bg-white
  }

  .custom-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
    @apply shadow-none bg-primary text-white
  }

  .custom-calendar .rmdp-day:not(.rmdp-day-hidden) span:hover:hover {
    @apply bg-primary-100 text-primary
  }

  .custom-calendar .rmdp-day.rmdp-deactive:hover {
    @apply bg-transparent
  }


  /* range picker */
  .custom-calendar .rmdp-day.rmdp-range {
    @apply text-white shadow-none bg-transparent rounded-lg
  }
  .custom-calendar .rmdp-day.rmdp-range span {
    @apply text-white shadow-none bg-primary rounded-md
  }

  /* general setting  */

  .custom-calendar .rmdp-week-day {
    @apply text-neutral-500 font-semibold
  }

  .custom-calendar .rmdp-day.rmdp-deactive {
    @apply text-neutral-300
  }

  .rmdp-day.rmdp-selected span:not(.highlight){
    @apply shadow-none
  }

  .custom-calendar .rmdp-day span {
    @apply rounded-md
  }


  .custom-calendar .b-deselect {
    @apply text-neutral-500 bg-white
  }

  .custom-calendar .rmdp-action-button {
    @apply text-neutral-500
  }

  .custom-calendar .rmdp-button:not(.rmdp-action-button) {
    @apply bg-transparent
  }

  .custom-calendar .rmdp-button:not(.rmdp-action-button):hover {
    @apply bg-transparent
  }
  
  .custom-calendar .rmdp-time-picker div input {
    width: 28px;
    color: inherit;
  }


  /* button group */
  .btn-group{
    @apply text-neutral-700 bg-white px-4 py-2 border
  }

  .first-btn-group {
    @apply rounded-tl-lg rounded-bl-lg
  }

  .middle-btn-group {
    @apply border-l-0
  }

  .last-btn-group {
    @apply rounded-tr-lg rounded-br-lg border-l-0
  }

  .active-btn-group{
    box-shadow: 0px 2px 4px -2px #1018280F inset;
    box-shadow: 0px 4px 8px -2px #1018281A inset;
    @apply text-neutral-800 font-semibold
  }


  /* maximize button */

}
.splash-screen {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f2f3f8;
  font-family: Poppins, Helvetica, 'sans-serif';
}

.splash-screen img {
  margin-bottom: 30px;
}

.tooltip-custom {
  padding: 2px 6px !important;
  font-size: 0.625rem !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
}

.tooltip-descName {
  background-color: white !important;
  color: #212529 !important;
  padding: 1rem !important;
  width: 270px !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  border: 1px solid #e9ecef;
  border-radius: 16px;
}

.tooltip-descName-arrow {
  border: 1px solid #dee2e6;
}

.menu-hidden {
  @apply fixed invisible -z-50
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #000000;

  /* Center slide text vertically */
  display: flex !important;
  justify-content: center;
  align-items: center;
  @apply w-full ms-auto me-auto
}


.swiper {
  @apply w-full ms-auto me-auto
}

.swiper-slide {
  @apply bg-cover bg-center cursor-pointer flex items-center
}

.gallery-slider-primary {
  height: 600px;
  width: 100%;
  @apply mb-5
}

.gallery-slider-primary .swiper-slide {
  @apply rounded-lg overflow-hidden
}

.gallery-slider-secondary {
  height: 100px;
  box-sizing: border-box;
  padding: 10px 0;
  max-width: 300px;
  width: auto;
}

.gallery-slider-secondary .swiper-slide {
  width: 100px;
  height: 100%;
  opacity: 0.4;
  @apply rounded-lg overflow-hidden
}

.gallery-slider-secondary .swiper-slide-thumb-active {
  opacity: 1;
}

.gallery-slider-primary .swiper-slide img {
  display: block;
  height: 100%;
  object-fit: cover;
  @apply mx-auto
} 


.gallery-slider-secondary .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} 



/* apex charts */
.apexcharts-axis-label {
  @apply text-xs text-neutral-400 font-normal
}

.apexcharts-tooltip {
  @apply !border-none !rounded-lg overflow-hidden
}

.custom-apexcharts-tooltip {
  @apply bg-neutral-800 text-white  px-4 py-2 text-xs border-neutral-800 shadow-none relative
}

.custom-apexcharts-tooltip-header {
  @apply pb-2 mb-2 border-b border-white/20
}

.custom-apexcharts-tooltip-header-date{
  @apply text-xs
}
.custom-apexcharts-tooltip-header-total{
  @apply text-xs font-bold
}

.custom-apexcharts-tooltip-body {
  @apply mb-2
}

.custom-apexcharts-tooltip-list {
  @apply list-outside list-disc ml-3
}

.custom-apexcharts-tooltip-list li > span {
  @apply text-white
}

.custom-apexcharts-tooltip-footer {
}






@tailwind components;

/* BUTTON */
@layer components {
  /* Base */
  .v-button {
    @apply outline-none font-medium rounded-lg transition-all duration-300;
  }

  /* Sizes */
  .v-button-tiny {
    @apply py-[8px] px-[14px] h-[32px] text-fs-10;
  }
  .v-button-small {
    @apply py-[10px] px-[16px] h-[40px] text-fs-9;
  }
  .v-button-medium {
    @apply py-[12px] px-[18px] h-[48px] text-fs-8;
  }
  .v-button-large {
    @apply py-[14px] px-[20px] h-[56px] text-fs-7;
  }
  .v-button-giant {
    @apply py-[16px] px-[28px] h-[64px] text-fs-6;
  }

  /* Variants and Colors */
  .v-button-filled-primary {
    @apply bg-primary-500 border border-primary-500  text-white enabled:hover:bg-primary-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-primary-300;
  }
  .v-button-outline-primary {
    @apply border border-primary-500  text-primary-500  hover:bg-primary-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-primary-300;
  }
  .v-button-ghost-primary {
    @apply border-none text-primary-500  hover:bg-primary-100 disabled:text-neutral-60;
  }

  .v-button-filled-secondary {
    @apply bg-secondary-500 border border-secondary-500  text-white enabled:hover:bg-secondary-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-secondary-300;
  }
  .v-button-outline-secondary {
    @apply border border-secondary-500  text-secondary-500  hover:bg-secondary-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-secondary-300;
  }
  .v-button-ghost-secondary {
    @apply border-none text-secondary-500  hover:bg-secondary-100 disabled:text-neutral-60;
  }

  .v-button-filled-tertiary {
    @apply bg-tertiary-500 border border-tertiary-500  text-white enabled:hover:bg-tertiary-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-tertiary-300;
  }
  .v-button-outline-tertiary {
    @apply border border-tertiary-500  text-tertiary-500  hover:bg-tertiary-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-tertiary-300;
  }
  .v-button-ghost-tertiary {
    @apply border-none text-tertiary-500  hover:bg-tertiary-100 disabled:text-neutral-60;
  }

  .v-button-filled-danger {
    @apply bg-danger-500 border border-danger-500  text-white enabled:hover:bg-danger-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-danger-300;
  }
  .v-button-outline-danger {
    @apply border border-danger-500  text-danger-500  hover:bg-danger-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-danger-300;
  }
  .v-button-ghost-danger {
    @apply border-none text-danger-500  hover:bg-danger-100 disabled:text-neutral-60;
  }

  .v-button-filled-success {
    @apply bg-success-500 border border-success-500  text-white enabled:hover:bg-success-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-success-300;
  }
  .v-button-outline-success {
    @apply border border-success-500  text-success-500  hover:bg-success-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-success-300;
  }
  .v-button-ghost-success {
    @apply border-none text-success-500  hover:bg-success-100 disabled:text-neutral-60;
  }

  .v-button-filled-warning {
    @apply bg-warning-500 border border-warning-500  text-white enabled:hover:bg-warning-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-warning-300;
  }
  .v-button-outline-warning {
    @apply border border-warning-500  text-warning-500  hover:bg-warning-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-warning-300;
  }
  .v-button-ghost-warning {
    @apply border-none text-warning-500  hover:bg-warning-100 disabled:text-neutral-60;
  }

  .v-button-filled-info {
    @apply bg-info-500 border border-info-500  text-white enabled:hover:bg-info-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-info-300;
  }
  .v-button-outline-info {
    @apply border border-info-500  text-info-500  hover:bg-info-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-info-300;
  }
  .v-button-ghost-info {
    @apply border-none text-info-500  hover:bg-info-100 disabled:text-neutral-60;
  }
}
/* BUTTON */

/* ICON BUTTON */
@layer components {
  /* Base */
  .v-icon-button {
    @apply outline-none font-medium rounded-lg transition-all duration-300;
  }

  /* Sizes */
  .v-icon-button-tiny {
    @apply p-[8px] w-8 h-8;
  }
  .v-icon-button-small {
    @apply p-[10px] w-10 h-10;
  }
  .v-icon-button-medium {
    @apply p-[12px] w-12 h-12;
  }
  .v-icon-button-large {
    @apply p-[14px] w-14 h-14;
  }
  .v-icon-button-giant {
    @apply p-[16px] w-16 h-16;
  }

  /* Variants and Colors */
  .v-icon-button-filled-primary {
    @apply bg-primary-500 border border-primary-500  text-white enabled:hover:bg-primary-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-primary-300;
  }
  .v-icon-button-outline-primary {
    @apply border border-primary-500  text-primary-500  enabled:hover:bg-primary-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-primary-300;
  }
  .v-icon-button-ghost-primary {
    @apply border-none text-primary-500  enabled:hover:bg-primary-100 disabled:text-neutral-60;
  }

  .v-icon-button-filled-secondary {
    @apply bg-secondary-500 border border-secondary-500  text-white enabled:hover:bg-secondary-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-secondary-300;
  }
  .v-icon-button-outline-secondary {
    @apply border border-secondary-500  text-secondary-500  enabled:hover:bg-secondary-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-secondary-300;
  }
  .v-icon-button-ghost-secondary {
    @apply border-none text-secondary-500  enabled:hover:bg-secondary-100 disabled:text-neutral-60;
  }

  .v-icon-button-filled-tertiary {
    @apply bg-tertiary-500 border border-tertiary-500  text-white enabled:hover:bg-tertiary-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-tertiary-300;
  }
  .v-icon-button-outline-tertiary {
    @apply border border-tertiary-500  text-tertiary-500  enabled:hover:bg-tertiary-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-tertiary-300;
  }
  .v-icon-button-ghost-tertiary {
    @apply border-none text-tertiary-500  enabled:hover:bg-tertiary-100 disabled:text-neutral-60;
  }

  .v-icon-button-filled-danger {
    @apply bg-danger-500 border border-danger-500  text-white enabled:hover:bg-danger-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-danger-300;
  }
  .v-icon-button-outline-danger {
    @apply border border-danger-500  text-danger-500  enabled:hover:bg-danger-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-danger-300;
  }
  .v-icon-button-ghost-danger {
    @apply border-none text-danger-500  enabled:hover:bg-danger-100 disabled:text-neutral-60;
  }

  .v-icon-button-filled-success {
    @apply bg-success-500 border border-success-500  text-white enabled:hover:bg-success-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-success-300;
  }
  .v-icon-button-outline-success {
    @apply border border-success-500  text-success-500  enabled:hover:bg-success-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-success-300;
  }
  .v-icon-button-ghost-success {
    @apply border-none text-success-500  enabled:hover:bg-success-100 disabled:text-neutral-60;
  }

  .v-icon-button-filled-warning {
    @apply bg-warning-500 border border-warning-500  text-white enabled:hover:bg-warning-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-warning-300;
  }
  .v-icon-button-outline-warning {
    @apply border border-warning-500  text-warning-500  enabled:hover:bg-warning-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-warning-300;
  }
  .v-icon-button-ghost-warning {
    @apply border-none text-warning-500  enabled:hover:bg-warning-100 disabled:text-neutral-60;
  }

  .v-icon-button-filled-info {
    @apply bg-info-500 border border-info-500  text-white enabled:hover:bg-info-600 disabled:opacity-40 focus:outline-none focus:ring focus:ring-info-300;
  }
  .v-icon-button-outline-info {
    @apply border border-info-500  text-info-500  enabled:hover:bg-info-100 disabled:border-neutral-60 disabled:text-neutral-60 focus:outline-none focus:ring focus:ring-info-300;
  }
  .v-icon-button-ghost-info {
    @apply border-none text-info-500  enabled:hover:bg-info-100 disabled:text-neutral-60;
  }
}
/* ICON BUTTON */

/* ALERT */
@layer components {
  /* Variants and Colors */
  .v-alert-filled-primary {
    @apply bg-primary-500 border border-primary-500  text-white;
  }
  .v-alert-outline-primary {
    @apply bg-primary-50 border border-primary-500  text-primary-500;
  }
  .v-alert-ghost-primary {
    @apply bg-primary-50 border-none text-primary-500;
  }

  .v-alert-filled-secondary {
    @apply bg-secondary-500 border border-secondary-500  text-white;
  }
  .v-alert-outline-secondary {
    @apply bg-secondary-50 border border-secondary-500  text-secondary-500;
  }
  .v-alert-ghost-secondary {
    @apply bg-secondary-50 border-none text-secondary-500;
  }

  .v-alert-filled-tertiary {
    @apply bg-tertiary-500 border border-tertiary-500  text-white;
  }
  .v-alert-outline-tertiary {
    @apply bg-tertiary-50 border border-tertiary-500  text-tertiary-500;
  }
  .v-alert-ghost-tertiary {
    @apply bg-tertiary-50 border-none text-tertiary-500;
  }

  .v-alert-filled-danger {
    @apply bg-danger-500 border border-danger-500  text-white;
  }
  .v-alert-outline-danger {
    @apply bg-danger-50 border border-danger-500  text-danger-500;
  }
  .v-alert-ghost-danger {
    @apply bg-danger-50 border-none text-danger-500;
  }

  .v-alert-filled-success {
    @apply bg-success-500 border border-success-500  text-white;
  }
  .v-alert-outline-success {
    @apply bg-success-50 border border-success-500  text-success-500;
  }
  .v-alert-ghost-success {
    @apply bg-success-50 border-none text-success-500;
  }

  .v-alert-filled-warning {
    @apply bg-warning-500 border border-warning-500  text-white;
  }
  .v-alert-outline-warning {
    @apply bg-warning-50 border border-warning-500  text-warning-500;
  }
  .v-alert-ghost-warning {
    @apply bg-warning-50 border-none text-warning-500;
  }

  .v-alert-filled-info {
    @apply bg-info-500 border border-info-500  text-white;
  }
  .v-alert-outline-info {
    @apply bg-info-50 border border-info-500  text-info-500;
  }
  .v-alert-ghost-info {
    @apply bg-info-50 border-none text-info-500;
  }
}
/* ALERT */

/* FORM  */
@layer components {
  .v-form-invalid {
    @apply border border-solid border-danger focus:border focus:border-solid focus:border-danger-300 focus:ring-danger-200;
  }
}

input:checked ~ .switched {
  background-color: #00487a !important;
}
